import Axios from 'axios'
import moment from 'moment'
export const RecibosServices = {
    api: {
        async preview(recibo) {
            return Axios.post('recibos/preview', { recibo: recibo }, { responseType: 'arraybuffer' })
                .then((response) => { return response })
                .catch((error) => { throw error })
        },
        async mostrar(link) {
            return Axios.get(`/recibos/mostrar/${link}`, { responseType: "arraybuffer" })
                .then((response) => { return response })
                .catch((error) => { throw error })
        },
        async emitir(id, params) {
            console.log(params)
            return Axios.get(`recibos/emitir/${id}`, { params: params, responseType: "arraybuffer" })
                .then((response) => { return response })
                .catch((error) => { throw error })
        },
        async misRecibos() {
            return Axios.get(`mis-recibos`)
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async misRecibosBuscar(params) {
            return Axios.get(`mis-recibos/filtrar`, { params: params })
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        },
        async pendientesPrevios(params) {
            return Axios.get(`mis-recibos/pendientes`, { params: params })
                .then((response) => { return response.data })
                .catch((error) => { throw error })
        }
    },
    header: [
        { value: 'Liquidacion', sortable: true },
        { value: 'Neto', sortable: true },
        { value: 'Estado', sortable: true },
        { value: 'Fecha impresión', sortable: true },
        { value: 'Observaciones', sortable: true }
    ],
    body: [{
            class: 'important',
            data: [
                { value: 'liquidacion.tipo_liquidacion', class: 'text-secondary text-small' },
                { value: 'liquidacion.periodo', class: 'text-bold' },
            ]
        },
        {
            class: 'w-100',
            data: [{
                value: 'neto',
                label: 'Neto',
                class: 'text-success',
                mutator: (value) => {
                    const options2 = { style: 'currency', currency: 'USD' };
                    const numberFormat = new Intl.NumberFormat('en-US', options2);
                    return numberFormat.format(value)
                }
            }]
        },
        {
            class: "w-100",
            data: [{
                value: "estado",
                label: "Estado",
                class: "nowrap",
                mutator: (estado) => {
                    let span = document.createElement("span");
                    span.classList.add("badge");
                    if (estado == "pendiente") {
                        span.innerText = "Pendiente";
                        span.classList.add("warning");
                    }
                    if (estado == "impreso") {
                        span.innerText = "Impreso";
                        span.classList.add("success");
                    }
                    if (estado == "impreso_con_observaciones") {
                        span.innerText = "Con observaciones";
                        span.classList.add("error");
                    }
                    return span.outerHTML;
                },
            }, ],
        },
        {
            class: "w-100",
            data: [{
                value: "fecha_recibido",
                label: "Fecha impreso",
                mutator: (fecha) => {
                    if (fecha) {
                        return moment(fecha, "YYYY-MM-DD").format("DD/MM/Y");
                    } else {
                        return "-";
                    }
                },
            }, ],
        },
        {
            class: "w-100",
            data: [{
                value: "observacion_empleado",
                label: "Observaciones",
                mutator: (obs) => {
                    if (!obs) {
                        return "-";
                    }
                    return obs;
                },
            }, ],
        },
    ],
    urlMostrarRecibo(link) {
        return `/recibos/mostrar/${link}`;
    },
    numberFormat(val) {
        const options2 = { style: 'currency', currency: 'USD' };
        const numberFormat = new Intl.NumberFormat('en-US', options2);
        return numberFormat.format(val)
    }
}