<template>
  <Modal @close="cerrarShowRecibo">
    <div slot="modal-body">
      <h3 class="card-title">Previsualizar recibo</h3>
      <div class="cols2 recibo-preview" ref="recibopreview">
        <div class="pdf-viewer" style="">
          <pdf
            v-if="src && !cargandoRecibo"
            ref="pdf"
            :src="src"
            :page="page"
            :rotate="rotate"
            :style="'width:calc(100% + ' + width + 'px );'"
          ></pdf>
          <div class="pdf-loader" v-if="cargandoRecibo && !src" style="">
            <h3>Cargando recibo.</h3>
            <h5>Por favor, espere.</h5>
          </div>
        </div>
        <div class="commands">
          <div class="zoom-container">
            <button
              class="btn btn-outline-success btn-block d-flex center"
              @click="width += 50"
            >
              <icon style="height: 15px" feather="zoom-in"></icon> Acercar
            </button>
            <button
              class="btn btn-outline-error btn-block d-flex center"
              @click="width -= 50"
            >
              <icon style="height: 15px" feather="zoom-out"></icon> Alejar
            </button>
          </div>
          <button
            v-if="!isPreview && !readonly"
            class="btn btn-primary btn-block"
            @click="emitir"
          >
            Firmar e imprimir / descargar recibo
          </button>
          <button
            v-else-if="!isPreview && readonly"
            class="btn btn-primary btn-block"
            @click="emitir"
          >
            Imprimir / descargar recibo
          </button>
          <button
            v-if="!readonly"
            class="btn btn-block btn-flat-error"
            @click="emitirConObservaciones"
          >
            Firmo en disconformidad
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import Modal from "@/components/elements/Modal";
import Axios from "axios";
import Swal from "sweetalert2";
import pdf from "vue-pdf";
import { RecibosServices } from "../services/RecibosServices";
export default {
  name: "ShowRecibo",
  components: {
    Modal,
    pdf,
  },
  data() {
    return {
      src: "",
      loadedRatio: 0,
      page: 1,
      width: 0,
      numPages: 0,
      rotate: 0,
      cargandoRecibo: false,
    };
  },
  props: {
    recibo: {
      value: {},
      required: true,
    },
    readonly: null,
    isPreview: null,
  },
  mounted() {
    this.getRecibo();
  },
  methods: {
    getRecibo() {
      this.cargandoRecibo = true;
      if (!this.recibo.id && this.readonly) {
        this.preview();
      } else {
        this.mostrar();
      }
    },
    emitir() {
      if (this.recibo.estado == "pendiente" && !this.readonly) {
        this.cargandoRecibo = true;
        this.src = null;
        RecibosServices.api
          .emitir(this.recibo.id, {
            estado: "impreso",
            observacion_empleado: "",
          })
          .then((res) => {
            this.src = res;
            this.openPDF();
          })
          .catch((err) => console.log(err))
          .finally(() => {
            this.cargandoRecibo = false;
            this.$emit("close");
          });
      } else {
        this.openPDF();
        this.$emit("close");
      }
    },
    emitirConObservaciones() {
      Swal.fire({
        title: "Está declarando que existen datos erróneos",
        text: "Por favor, describa la información mal cargada",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        inputValidator: (value) => {
          if (!value) {
            return "Debe cargar una descripción para poder firmar en disconformidad.";
          }
        },
        showCancelButton: true,
        confirmButtonText: "Guardar",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed && result.value) {
          RecibosServices.api
            .emitir(this.recibo.id, {
              estado: "impreso_con_observaciones",
              observacion_empleado: result.value,
            })
            .then((res) => {
              this.src = res;
              this.openPDF();
            })
            .catch((err) => console.log(err))
            .finally(() => {
              this.cargandoRecibo = false;
              this.$emit("close");
            });
        }
      });
    },
    preview() {
      RecibosServices.api
        .preview(this.recibo)
        .then((res) => {
          this.src = res;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.cargandoRecibo = false;
        });
    },
    mostrar() {
      RecibosServices.api
        .mostrar(this.recibo.link)
        .then((res) => {
          this.src = res;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.cargandoRecibo = false;
        });
    },
    openPDF() {
      let url = Axios.defaults.baseURL + RecibosServices.urlMostrarRecibo(this.recibo.link);
      var fileLink = document.createElement("a");
      fileLink.href = url;
      fileLink.target = "_blank";
      fileLink.style.display = "none";
      this.$refs["recibopreview"].appendChild(fileLink);
      fileLink.click();
    },
    cerrarShowRecibo() {
      if (this.recibo.estado == "pendiente" && !this.readonly) {
        Swal.fire("Atención", `<p>Debes firmar el recibo antes de salir.</p>`, "warning");
      } else {
        this.$emit("close");
      }
    },
  },
};
</script>
<style scoped>
.recibo-preview > .commands {
  order: 1;
}
.recibo-preview > .pdf-viewer {
  order: 2;
}
.pdf-loader {
  background: white;
  width: 800px;
  height: 1000px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.pdf-viewer {
  padding: 15px;
  height: calc((100vw - 40px) * 1.41);
  width: calc(100vw - 40px);
  background: grey;
  overflow: auto;
}
.zoom-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Where the magic happens */
  grid-auto-rows: auto;
  grid-gap: 1rem;
  margin: auto;
}

@media only screen and (min-width: 63em) {
  .recibo-preview > .pdf-viewer {
    order: 1;
  }
  .recibo-preview > .commands {
    order: 2;
  }
  .pdf-viewer {
    width: 350px;
    height: calc(350px * 1.414);
  }
}
@media only screen and (min-width: 1500px) {
  .pdf-viewer {
    width: 550px;
    height: calc(550px * 1.414);
  }
}
</style>
