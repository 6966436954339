<template>
  <div class="card">
    <BlockInterface v-if="loading" />
    <h3 class="card-title">Cargar liquidación</h3>
    <div class="third-center">
      <file-select v-model="file"></file-select>
    </div>
    <div v-if="liquidaciones.length > 0">
      <h4 class="card-title">Vista previa</h4>
      <div v-for="liquidacion in liquidaciones" :key="liquidacion.numero">
        <div class="cols2">
          <pd-input
            class="my-0"
            readonly
            classInput="form-control readonly text-bold text-success"
            v-model="liquidacion.periodo"
            label="Período"
            id="periodo"
            placeholder="periodo"
          ></pd-input>
          <pd-input
            class="my-0"
            readonly
            classInput="form-control readonly text-bold text-success"
            v-model="liquidacion.tipo"
            label="Tipo"
            id="Tipo"
            placeholder="Tipo"
          ></pd-input>
          <pd-input
            class="my-0"
            readonly
            classInput="form-control readonly text-bold text-secondary"
            :value="recibos_liquidacion(liquidacion)"
            label="Recibos liquidados"
            id="Recibosliquidados"
            placeholder="Recibos liquidados"
          ></pd-input>
          <pd-input
            class="my-0"
            readonly
            classInput="form-control readonly text-bold text-secondary"
            :value="neto_liquidacion(liquidacion)"
            label="Neto total"
            id="Netototal"
            placeholder="Neto total"
          ></pd-input>
        </div>
        <table-list
          ref="tablelist"
          :showFilter="true"
          :data="liquidacion.recibos"
          :header="headers"
          :body="body"
          @rowClicked="previewRecibo"
        ></table-list>
        <ShowRecibos
          v-if="reciboSelected"
          :recibo="reciboSelected"
          @close="close"
          :readonly="true"
          :preview="true"
        ></ShowRecibos>
      </div>
      <div class="d-flex right mt-1">
        <button class="btn btn-primary" @click="save">
          Guardar liquidacion
        </button>
      </div>
    </div>
    <div class="my-3" v-else-if="failed">
      <h3 class="card-title text-center">No se ha podido cargar el archivo</h3>
    </div>
  </div>
</template>
<script>
import XLSX from "xlsx";
import { LiquidacionesServices } from "../services/LiquidacionesServices";
import TableList from "@/components/elements/TableList";
import FileSelect from "@/components/elements/FileSelect";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import ShowRecibos from "@/modules/recibos/views/ShowRecibos";
import Swal from "sweetalert2";
import BlockInterface from "@/components/elements/BlockInterface";
export default {
  name: "NuevaLiquidacion",
  components: {
    BlockInterface,
    FileSelect,
    TableList,
    ShowRecibos,
  },
  data() {
    return {
      failed: false,
      file: null,
      filas: [],
      liquidaciones: [],
      headers: [
        { value: "Persona", sortable: true },
        { value: "Legajo", sortable: true },
        { value: "Neto", sortable: true },
      ],
      body: [
        {
          class: "important",
          data: [
            { value: "nombre", class: "text-primary" },
            {
              value: "cuil",
              class: "text-small text-secondary",
              mutator: (cuil) => {
                return PersonasService.formatCuil(cuil);
              },
            },
          ],
        },
        {
          class: "w-100",
          data: [{ value: "numero_legajo", class: "", label: "Legajo" }],
        },
        {
          class: "w-100",
          data: [
            {
              value: "neto",
              class: "",
              label: "Neto",
              mutator: (number) => {
                return LiquidacionesServices.numberFormat(number);
              },
            },
          ],
        },
      ],
      personas: null,
      reciboSelected: null,
      loading: false,
    };
  },
  computed: {},
  watch: {
    file: function (file) {
      if (file) {
        this.loading = true;
        this.leerXLS()
          .then((filas) => this.sortLiquidaciones(filas))
          .catch((error) => {
            Swal.fire(
              "Error",
              `<p>Ocurrió un error interno</p> <p>${error}</p>`,
              "error"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    PersonasService.api.getAllForSelect().then((personas) => {
      this.personas = personas;
    });
  },
  methods: {
    async leerXLS() {
      this.failed = false;
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = function () {
          let arraybuffer = this.result;
          /* convert data to binary string */
          let data = new Uint8Array(arraybuffer);
          let arr = new Array();

          for (let i = 0; i != data.length; ++i)
            arr[i] = String.fromCharCode(data[i]);
          let bstr = arr.join("");

          /* Call XLSX */
          let workbook = XLSX.read(bstr, { type: "binary" });
          /* DO SOMETHING WITH workbook HERE */
          let first_sheet_name = workbook.SheetNames[0];
          /* Get worksheet */
          let worksheet = workbook.Sheets[first_sheet_name];

          let filas = XLSX.utils.sheet_to_json(worksheet, {
            raw: true,
            blankrows: true,
            defval: "-",
            header: 1,
          });
          resolve(filas);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(this.file);
      });
    },
    sortLiquidaciones(filas) {
      this.filas = filas;
      let liquidaciones = [];
      let i = 0;
      try {
        for (let fila of filas) {
          if (i > 0 && fila[10]) {
            let liquidacion = LiquidacionesServices.findOrNewLiquidacion(
              fila,
              liquidaciones
            );
            let recibo = LiquidacionesServices.findOrNewRecibo(
              fila,
              liquidacion.recibos,
              this.personas
            );
            let concepto = LiquidacionesServices.findOrNewConcepto(
              fila,
              recibo
            );
          }
          i++;
        }
        this.liquidaciones = liquidaciones;
      } catch (error) {
        Swal.fire("error", `<p>${error}</p>`, "error");
        this.failed = true;
        this.file = null;
      }
    },
    save() {
      LiquidacionesServices.api
        .save(this.liquidaciones)
        .then((response) => {
          if (response.success) {
            this.$router.push({ name: "ListarLiquidaciones" });
          } else {
            Swal.fire("Error", `<p>${response.mensaje}.</p>`, "error");
          }
        })
        .catch((error) => {
          Swal.fire("Error", `<p>${error}</p>`, "error");
        });
    },
    previewRecibo(recibo) {
      let reciboSelected = recibo;
      let liq = {
        tipo_liquidacion: this.liquidaciones[0].tipo,
        periodo: this.liquidaciones[0].periodo,
        numero: this.liquidaciones[0].numero,
      };
      reciboSelected.liquidacion = liq;
      this.reciboSelected = reciboSelected;
    },
    close() {
      this.reciboSelected = null;
    },
    recibos_liquidacion(liquidacion) {
      return liquidacion.recibos.length;
    },
    neto_liquidacion(liquidacion) {
      return LiquidacionesServices.numberFormat(liquidacion.recibos.reduce((a, b) => a + (b['neto'] || 0), 0));
    },
  },
};
</script>